import { alwaysNeverAnswers, agreeDisagreeAnswers, cslpmRightData } from './base-answers';

export const cslpmEpmoImpact = [
  {
    name: 'Effect of EPMO',
    key: 'epmoeffect',
    category: 'epmoeffect',
    questions: [
      {
        text: 'Do you have a clear understanding of the purpose and outcome expected for EPMO managed projects? ',
        key: 'cslpm-outcomes-effect',
        answers: alwaysNeverAnswers,
      },
      {
        text: 'The EPMO actively works with me to address dependencies, impediments, and risks.',
        key: 'cslpm-workswith-effect',
        answers: agreeDisagreeAnswers,
      },
      {
        text: 'The EPMO plays an active role in enhancing our project delivery.',
        key: 'cslpm-delivery-effect',
        answers: agreeDisagreeAnswers,
      },
      {
        text: 'EPMO managed projects allow us to do “good” work as we go - quality and compliance are not an afterthought.',
        key: 'cslpm-quality-effect',
        answers: alwaysNeverAnswers,
      },
      {
        text: 'Do you have the right data to determine if a project should continue, stop, or start?',
        key: 'cslpm-determination-effect',
        answers: alwaysNeverAnswers,
      },
      {
        text: 'Do you have the right data to determine which work items take priority when faced with two competing requests?',
        key: 'cslpm-rightdata-effect',
        answers: cslpmRightData,
      },
      {
        text: 'Reducing delays is a way to improve our ability to deliver.  Over the last six months, what types of delays have you experienced (e.g., waiting for budget approval or waiting on a dependency to be met)?',
        key: 'cslpm-delays-effect',
        type: 'long-text',
      },
    ],
  },
];
