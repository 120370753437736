import { csRole, csDomain, csTenure } from './base-answers';

export const cslpmDemographics = [
  {
    name: 'Demographics',
    key: 'demographics',
    category: 'demographics',
    questions: [
      {
        text: 'What role best describes your role at CareSource?',
        key: 'cslpm-demo-role',
        answers: csRole,
        type: 'unscored-likert',
      },
      {
        text: 'In what domain do you primarily work?',
        key: 'cslpm-demo-domain',
        answers: csDomain,
        type: 'unscored-likert',
      },
      {
        text: 'What is your tenure at CareSource?',
        key: 'cslpm-demo-tenure',
        answers: csTenure,
        type: 'unscored-likert',
      },
    ],
  },
];
